/* source: https://boyl.es/post/css-reset-for-tables/ */
table.grid-table thead,
table.grid-table tbody,
table.grid-table tr {
	display: grid;
	grid-column: 1 / -1;
	grid-template-columns: subgrid;
}

table.grid-table td,
table.grid-table th {
	display: flex;
	align-items: center;
	justify-content: center;
}

table.grid-table caption {
	grid-column: 1 / -1;
}

.sales-interest-cards div {
	@apply flex-[0_0_calc(50%-10px)] md:flex-[0_0_calc(32%-10px)];
}

.grecaptcha-badge {
	@apply invisible;
}

#twilight-sea-9995 .sv_container {
	z-index: auto !important;
}

#twilight-sea-9995 .sv_body {
	padding-inline: 0 !important;
}

#twilight-sea-9995 .sv_complete_btn {
	@apply bg-primary text-white hover:bg-examine-purple-400 active:bg-examine-purple-800 disabled:bg-[#babbbd];
}
