:root {
    --chart-purple: 275.59 33.33% 34.71%;
    --chart-green: 186.09 65.09% 41.57%;
    --chart-teal: 162.25 72.53% 45.69%;
}

.dark {
    --chart-purple: 275.59 33.33% 34.71%;
    --chart-green: 186.09 65.09% 41.57%;
    --chart-teal: 162.25 72.53% 45.69%;
}